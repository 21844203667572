import { defineStore } from "pinia";
import { onMounted, ref } from "vue";
import { useIndexedDB } from "@/composables/useIndexedDB";
import { useAreaStore } from "@/store/areaStore";
import { useRoute } from "vue-router";

export const usePictureStore = defineStore("picture", () => {
  const pictures = ref([]);
  const useIndexed = useIndexedDB();
  const { id_area } = useAreaStore();
  const route = useRoute();

  function resetPictures() {
    pictures.value = [];
  }

  function addPicture(picture) {
    pictures.value.push(picture);
  }

  function removePicture(index) {
    pictures.value = pictures.value.filter((_, i) => i !== index);
  }

  function loadImages() {
    useIndexed.searchDatas("images", id_area, "idxAreaId").then((rsp) => {
      if (rsp !== undefined) {
        let before = route.path === "/inicio";
        rsp.map((image) => {
          image.url = URL.createObjectURL(image.image);
        });
        let photos = rsp.filter((img) => img.isBefore === before);
        pictures.value = photos;
      }
    });
  }

  onMounted(() => {
    loadImages();
  });

  return { pictures, resetPictures, addPicture, removePicture, loadImages };
});
