import { ref } from "vue";
import moment from "moment/moment";

export function useTimeZone() {
  const hour = ref("");
  const date = ref("");

  async function getDateCurrent(date_current) {
    hour.value = moment(date_current).format("HH:mm:ss");
    date.value = moment(date_current).format("YYYY-MM-DD");
    saveLocalStorage();
  }

  function saveLocalStorage() {
    //localStorage.setItem("hour_serve", hour.value);
    localStorage.setItem("dateTime_serve", `${date.value} ${hour.value}`);
    localStorage.setItem(
      "dateTime_device",
      moment().tz("America/Costa_Rica").format("YYYY-MM-DD HH:mm:ss")
    );
  }

  return { hour, date, getDateCurrent };
}
