import { ref } from "vue";
import moment from "moment";
export function useIndexedDB() {
  const db = ref({});
  const error = ref("");
  const createdDb = () => {
    let response = indexedDB.open("soClean", 1);

    response.onerror = function (e) {
      error.value = `${e.code} its message is: ${e.message}`;
    };

    response.onupgradeneeded = function (ev) {
      db.value = ev.target.result;
      let database = ev.target.result;
      if (!database.objectStoreNames.contains("buildings")) {
        let tableCreated = database.createObjectStore("buildings", {
          keyPath: "building_id",
        });
        tableCreated.createIndex("idxBuildingId", "building_id", {
          unique: true,
        });
      }
      if (!database.objectStoreNames.contains("areas")) {
        let tableCreated = database.createObjectStore("areas", {
          keyPath: "id",
        });
        tableCreated.createIndex("idxId", "id", { unique: true });
      }
      if (!database.objectStoreNames.contains("images")) {
        let tableCreated = database.createObjectStore("images", {
          keyPath: "id",
          autoIncrement: true,
        });
        tableCreated.createIndex("idxIdImage", "id", { unique: true });
        tableCreated.createIndex("idxAreaId", "area_id", { unique: false });
      }
      if (!database.objectStoreNames.contains("activities")) {
        let tableCreated = database.createObjectStore("activities", {
          keyPath: "id",
          autoIncrement: true,
        });
        tableCreated.createIndex("idxIdActivities", "id", { unique: true });
        tableCreated.createIndex("idxAreaId", "area_id", { unique: false });
        tableCreated.createIndex("idxStatus", "status", { unique: false });
        tableCreated.createIndex("idxParentLocalId", "parent_local_id", {
          unique: false,
        });
      }
      if (!database.objectStoreNames.contains("users")) {
        let tableCreated = database.createObjectStore("users", {
          keyPath: "id",
          autoIncrement: true,
        });
        tableCreated.createIndex("idxId", "id", { unique: true });
      }
    };
  };

  const insertData = (table, payload) => {
    return new Promise((resolve) => {
      let db = indexedDB.open("soClean", 1);
      db.onsuccess = function (ev) {
        let database = ev.target.result;
        let transaction = database.transaction(table, "readwrite");
        let tableFind = transaction.objectStore(table);
        const addRequest = tableFind.add(payload);
        addRequest.onsuccess = (ev) => {
          console.log("Datos insertados con éxito.");
          resolve(ev.target.result);
        };

        addRequest.onerror = (event) => {
          console.log("Error al insertar datos:", event.target.error);
        };
      };
    });
  };

  const updateData = (table, payload) => {
    return new Promise((resolve) => {
      let db = indexedDB.open("soClean", 1);
      db.onsuccess = function (ev) {
        let database = ev.target.result;
        let transaction = database.transaction(table, "readwrite");
        let tableFind = transaction.objectStore(table);
        tableFind.put(payload);
        resolve();
      };
    });
  };

  const searchData = (table, search, withIndex = "") => {
    return new Promise((resolve) => {
      let db = indexedDB.open("soClean", 1);
      db.onsuccess = function (ev) {
        let database = ev.target.result;
        const tx = database.transaction([table], "readonly");
        const request = tx.objectStore(table);
        let result = "";
        if (withIndex === "") {
          result = request.get(search);
        } else {
          const index = request.index(withIndex);
          result = index.get(search);
        }
        result.onsuccess = (ev) => resolve(ev.target.result);

        //result.onerror = (ev) => reject(ev.target.error);
      };
    });
  };

  const searchDatas = (table, search, withIndex = "") => {
    return new Promise((resolve) => {
      let db = indexedDB.open("soClean", 1);
      db.onsuccess = function (ev) {
        let database = ev.target.result;
        const tx = database.transaction([table], "readonly");
        const request = tx.objectStore(table);
        let result = "";
        if (withIndex === "") {
          result = request.getAll(search);
        } else {
          const index = request.index(withIndex);
          result = index.getAll(search);
        }
        result.onsuccess = (ev) => resolve(ev.target.result);

        //result.onerror = (ev) => reject(ev.target.error);
      };
    });
  };

  const deleteData = (table, key) => {
    let db = indexedDB.open("soClean", 1);

    db.onsuccess = function (ev) {
      let database = ev.target.result;
      let transaction = database.transaction(table, "readwrite");
      let tableFind = transaction.objectStore(table);
      tableFind.delete(key);
      transaction.complete;
    };
  };
  const saveImage = (payload) => {
    return new Promise((resolve, reject) => {
      let db = indexedDB.open("soClean", 1);
      db.onsuccess = function (ev) {
        let database = ev.target.result;
        const transaction = database.transaction(["images"], "readwrite");
        const imgStore = transaction.objectStore("images");
        let response = imgStore.add({
          ...payload,
          created_at: moment().format("YYYY-MM-DD hh:mm:ss"),
        });

        response.onsuccess = (ev) => resolve(ev.target.result);
        response.onerror = (ev) => reject(ev.target.result);
      };
    });
  };

  const getImages = () => {
    return new Promise((resolve, reject) => {
      let db = indexedDB.open("soClean", 1);
      db.onsuccess = function (ev) {
        let database = ev.target.result;
        const tx = database.transaction(["images"], "readonly");
        const request = tx.objectStore("images");
        let result = request.getAll();
        result.onsuccess = (ev) => resolve(ev.target.result);

        result.onerror = (ev) => reject(ev.target.error);
      };
    });
  };

  const getInfo = (table) => {
    return new Promise((resolve) => {
      let db = indexedDB.open("soClean", 1);
      db.onsuccess = function (ev) {
        let database = ev.target.result;
        if (database.objectStoreNames.contains(table)) {
          const tx = database.transaction([table], "readonly");
          const request = tx.objectStore(table);
          let result = "";
          result = request.getAll();
          result.onsuccess = (ev) => resolve(ev.target.result);
        }
      };
    });
  };

  return {
    createdDb,
    insertData,
    updateData,
    searchData,
    saveImage,
    searchDatas,
    deleteData,
    getImages,
    getInfo,
  };
}
