<template>
  <div>
    <div class="container" v-show="!succes">
      <div id="inicio-sesion" style="position: relative">
        <button @click="reloadForce()" class="btn-rounded">
          <img
            src="../assets/refresh.svg"
            alt="refresh"
            width="15"
            height="20"
          />
        </button>
        <img
          src="../assets/soclean.png"
          alt="Logo de SoClean"
          class="logo-soclean"
          id="logo-form"
        />
        <div>
          <h3 id="titulo-form">Iniciar Sesión</h3>
          <p class="textos-secundarios">Ingresa con tu usuario y contraseña</p>
        </div>
        <div class="form-container">
          <div>
            <label class="textos-secundarios" id="label-input" for=""
              >Usuario:</label
            >
            <br />
            <input class="input-form" type="text" v-model="form.email" />
          </div>
          <div>
            <label class="textos-secundarios" id="label-pass" for=""
              >Contraseña:</label
            >
            <br />
            <input
              class="input-form"
              :type="showPass ? 'text' : 'password'"
              v-model="form.password"
            />
            <button
              class="button-open-eye"
              type="button"
              @click.prevent="showPass = !showPass"
            >
              <img
                src="../assets/openEye.png"
                alt="Ver password"
                class="open-eye"
                v-if="!showPass"
              />
              <img
                src="../assets/closedEye.png"
                alt="Dejar de ver password"
                class="open-eye"
                v-if="showPass"
              />
            </button>
          </div>
        </div>
        <div id="recovery-container">
          <router-link :to="{ name: 'restore' }" id="recovery-password">
            ¿Olvidó su contraseña?
          </router-link>
        </div>
        <button
          type="button"
          @click.prevent="login"
          class="button-general"
          id="boton-form"
        >
          Continuar
        </button>
      </div>
      <div class="col-12" v-if="Object.keys(validationErrors).length > 0">
        <ul class="mb-0">
          <li v-for="(value, key) in validationErrors" :key="key">
            {{ value[0] }}
          </li>
        </ul>
      </div>
      <div class="table-overlay" v-show="loadLogin" id="loading-span">
        <div class="table-overlay-container">
          <span>Loading...</span>
        </div>
      </div>
      <div v-if="message" class="form-group col-12" v-show="!loadLogin">
        <p class="text-danger fw-bold" id="mensaje-error">* {{ message }}</p>
      </div>
    </div>
  </div>
  <div class="container h-100" v-show="succes">
    <div id="succes-div">
      <img src="../assets/succes.png" alt="Succes Image" class="succes-image" />
      <h4 id="succes-title">Información de acceso</h4>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import axios from "@/config/axios";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/authStore";
import { useIndexedDB } from "@/composables/useIndexedDB";
import { useActivities } from "@/composables/useActivities";
import { useTimeZone } from "@/composables/useTimeZone";

const message = ref("");
const loadLogin = ref(false);
const succes = ref(false);
const showPass = ref(false);
const form = reactive({
  email: "",
  password: "",
});
const validationErrors = ref({});
const authStore = useAuthStore();
const useActivity = useActivities();
const { id, name, token, employee_id, staff_id, type_user } =
  storeToRefs(authStore);
const useIndexDB = useIndexedDB();
const router = useRouter();
const timeZoneUse = useTimeZone();

async function login() {
  loadLogin.value = true;
  await verifyActivitiesPending();
  await axios.get("sanctum/csrf-cookie");
  await axios
    .post("api/login", form)
    .then(({ data }) => {
      useIndexDB.createdDb();
      autocompleteAuthStore(data);
      setTimeout(() => {
        if (data.user.staff_id) {
          router.push({ name: "workday" });
        } else {
          router.push({ name: "area" });
        }
      }, 2000);
    })
    .catch((e) => {
      message.value = e.response?.data?.error;
    })
    .finally(() => {
      loadLogin.value = false;
    });
}

function autocompleteAuthStore(data) {
  timeZoneUse.getDateCurrent(data.datetime_current);
  id.value = data.user.id;
  name.value = data.user.name;
  token.value = data.token;
  employee_id.value = data.user.employee_id;
  staff_id.value = data.user.staff_id;
  type_user.value = data.user.type_user;
  authStore.saveToLocalStorage();
  succes.value = true;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.value}`;
  useIndexDB.insertData("users", {
    token: data.token,
    name: data.user.name,
    user_id: data.user.id,
    xrsf_token: document.cookie.match(/XSRF-TOKEN=([^;]+)/)[1],
  });
}

async function verifyActivitiesPending() {
  let resp = await useIndexDB.searchDatas("activities", "fw", "idxStatus");
  if (resp.length) {
    await useActivity.sendActivityLocal();
    await useActivity.sendActivityFinishWorkday();
  }
}

function reloadForce() {
  if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({ type: "CLEAR_CACHE" });
  }

  window.location.href =
    window.location.href.split("?")[0] + "?nocache=" + new Date().getTime();
}
</script>
<style scoped>
.btn-rounded {
  border: 0.25px solid gray;
  border-radius: 20px;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
}
.btn-rounded:hover {
  background: rgb(242, 242, 232);
}
</style>
