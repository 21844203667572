import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import QrReader from "vue3-qr-reader";
import Camera from "simple-vue-camera";
import "./styles/main.css";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(QrReader);
// eslint-disable-next-line vue/multi-word-component-names
app.component("camera", Camera);
app.mount("#app");
