/* eslint-disable */
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import {useIndexedDB} from "@/composables/useIndexedDB";

export const useAuthStore = defineStore("auth", () => {
  const id = ref(0);
  const name = ref("");
  const token = ref("");
  const employee_id = ref(0);
  const staff_id = ref(0);
  const type_user = ref('');

  const restoreAuth = () => {
    const now = new Date().getTime();
    const expirationDate = localStorage.getItem("auth:expirationDate");
    if (now >= expirationDate) {
      logout();
    } else {
      const storedId = localStorage.getItem("auth:id");
      const storedName = localStorage.getItem("auth:name");
      const storedToken = localStorage.getItem("auth:token");
      const storedEmployeeId = localStorage.getItem("auth:employee_id");
      const storedStaffId = localStorage.getItem("auth:staff_id");
      const storedTypeUser = localStorage.getItem("auth:type_user");

      if (storedId) id.value = parseInt(storedId);
      if (storedName) name.value = storedName;
      if (storedToken) token.value = storedToken;
      if (storedEmployeeId) employee_id.value = storedEmployeeId;
      if (storedStaffId) staff_id.value = storedStaffId;
      if (storedTypeUser) type_user.value = storedTypeUser;
    }
  };

  const saveToLocalStorage = () => {
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 12);
    localStorage.setItem("auth:id", id.value.toString());
    localStorage.setItem("auth:name", name.value);
    localStorage.setItem("auth:token", token.value);
    localStorage.setItem("auth:employee_id", employee_id.value);
    localStorage.setItem("auth:staff_id", staff_id.value);
    localStorage.setItem("auth:type_user", type_user.value);
    localStorage.setItem("auth:expirationDate", expirationDate.getTime());
  };

  async function logout() {
    token.value = null;
    name.value = null;
    id.value = 0;
    employee_id.value = 0;

    localStorage.removeItem("auth:id");
    localStorage.removeItem("auth:name");
    localStorage.removeItem("auth:token");
    localStorage.removeItem("auth:employee_id");
    localStorage.removeItem("auth:staff_id");
    localStorage.removeItem("area:current");
    localStorage.removeItem("auth:expirationDate");
    localStorage.removeItem("building:current");
    localStorage.removeItem("auth:type_user");
    localStorage.removeItem("workday:open");
  }

  async function deleteUserIndexedDB(){
    const useIndexed = useIndexedDB();
    let user = await useIndexed.getInfo("users");
    let user_id = user.length && user[0].id;
    if (user_id){
      await useIndexed.deleteData("users", user_id);
    }
  }

  restoreAuth();
  return { id, name, token, logout, employee_id, staff_id, type_user, saveToLocalStorage, deleteUserIndexedDB };
});
