import axios from "@/config/axios";

export default {
  storeActivity(payload) {
    return axios.post("api/v1/activities", payload);
  },
  updateActivity(payload) {
    return axios.post("api/v1/activities/comment_observation", payload);
  },
  saveMedia(payload) {
    return axios.post("api/v1/activities/media", payload);
  },
  getLastActivities(params) {
    return axios.get("api/v1/activities/last_activities", {
      params: { ...params },
    });
  },
};
