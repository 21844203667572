import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sesion",
    name: "login",
    component: LoginView,
  },
  {
    path: "/jornada",
    name: "workday",
    component: () =>
      import(
        /* webpackChunkName: "soclean-chunk" */ "../views/WorkdayView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/jornada-ok",
    name: "workday-ok",
    component: () =>
      import(
        /* webpackChunkName: "soclean-chunk" */ "../views/WorkdayConfirmView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/area",
    name: "area",
    component: () =>
      import(/* webpackChunkName: "soclean-chunk" */ "../views/AreaView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/area-ok",
    name: "area-ok",
    component: () =>
      import(/* webpackChunkName: "soclean-chunk" */ "../views/AreaOkView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/tipo_limpieza",
    name: "type_clean",
    component: () =>
      import(
        /* webpackChunkName: "soclean-chunk" */ "../views/BeforeCleanView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/inicio",
    name: "start",
    component: () =>
      import(
        /* webpackChunkName: "soclean-chunk" */ "../views/BeforeCleanView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/fin",
    name: "end",
    component: () =>
      import(
        /* webpackChunkName: "soclean-chunk" */ "../views/BeforeCleanView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/comments",
    name: "comments",
    component: () =>
      import(
        /* webpackChunkName:"soclean-chunk" */ "../views/BeforeCleanView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/siguiente",
    name: "next",
    component: () =>
      import(
        /* webpackChunkName: "soclean-chunk" */ "../views/FinishWorkdayView"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/finish",
    name: "finisOk",
    component: () =>
      import(/* webpackChunkName: "soclean-chunk" */ "../views/FinishOkView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/historial",
    name: "record",
    component: () =>
      import(/* webpackChunkName: "soclean-chunk" */ "../views/RecordView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/restore-password",
    name: "restore",
    component: () =>
      import(
        /* webpackChunkName: "soclean-chunk" */ "../views/RestorePasswordView"
      ),
  },
  {
    path: "/salir",
    name: "logout",
    component: () =>
      import(/* webpackChunkName: "soclean-chunk" */ "../views/LogoutView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/reportar",
    name: "report",
    component: () =>
      import(/* webpackChunkName: "soclean-chunk" */ "../views/ReportView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/reporte-enviado",
    name: "report-ok",
    component: () =>
      import(
        /* webpackChunkName: "soclean-chunk" */ "../views/ReportOkView.vue"
      ),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  const auth = localStorage.getItem("auth:token");
  if (to.meta.requiresAuth && !auth) {
    return {
      path: "/",
      query: { redirect: to.fullPath },
    };
  }
});

export default router;
