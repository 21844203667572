/* eslint-disable */
<template>
  <div class="container-home">
    <img src="../assets/Group.png" alt="Background Image" class="top-image" />
    <svg
      class="logo-soclean"
      width="169"
      height="107"
      viewBox="0 0 169 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.8145 94.8161C64.9632 95.1885 64.0587 95.4545 63.1542 95.6673C62.2497 95.8802 61.3452 95.9866 60.3876 95.9866C59.1106 95.9866 57.8337 95.7737 56.6632 95.3481C55.4927 94.9225 54.4286 94.284 53.4177 93.4327C51.4491 91.4109 50.4382 89.0167 50.4382 86.3033C50.4382 84.4943 50.8107 83.0046 51.6087 81.7276C52.4068 80.4507 53.1517 79.4398 53.8966 78.8014C54.6414 78.1097 55.3863 77.6308 56.0248 77.3648C56.7164 77.0988 57.2485 76.886 57.6741 76.7796C58.0997 76.6732 58.5254 76.62 59.0042 76.5667C59.4299 76.5135 59.9087 76.5135 60.2812 76.5135C60.9196 76.5135 61.5581 76.5668 62.2497 76.6732C62.9414 76.7796 63.6331 76.9392 64.3247 77.152L65.7081 77.7905V80.2911C64.1651 79.1738 62.4093 78.6417 60.5472 78.6417C59.2703 78.6417 58.0997 78.9078 56.9824 79.3866C55.8651 79.9187 54.9607 80.6635 54.1626 81.7276C53.6837 82.4193 53.3645 83.111 53.0985 83.909C52.8325 84.7071 52.726 85.5052 52.726 86.3565C52.726 87.793 53.0985 89.1231 53.7901 90.4C54.4818 91.677 55.4927 92.5814 56.7696 93.2199C58.0465 93.8584 59.3235 94.1776 60.6004 94.1776C61.9837 94.1776 63.2606 93.8584 64.3779 93.1667L65.7081 92.3154V94.8161H65.8145Z"
        fill="#002A41"
      />
      <path
        d="M88.5328 95.6674H79.3284V76.9392H81.6694V93.6456H88.586V95.6674H88.5328Z"
        fill="#002A41"
      />
      <path
        d="M114.444 95.6674H104.281V76.9392H114.444V78.961H106.516V84.4943H114.444V86.4097H106.516V93.6456H114.444V95.6674Z"
        fill="#002A41"
      />
      <path
        d="M143.281 95.6674H140.674L138.28 89.868H130.831L128.437 95.6674H126.042L134.236 76.4072H134.821L143.281 95.6674ZM137.535 88.1655L134.396 80.0783L131.363 88.1655H137.535Z"
        fill="#002A41"
      />
      <path
        d="M168.553 96.0398H168.074L154.401 81.036V95.6673H152.326V76.5135H152.858L166.372 91.5706V76.9924H168.5V96.0398H168.553Z"
        fill="#002A41"
      />
      <path
        d="M2.71345 103.329C2.71345 103.382 2.71345 103.435 2.71345 103.489C2.71345 103.808 2.60704 104.127 2.39422 104.34C2.1814 104.553 1.91538 104.712 1.54294 104.765H0.585255V106.894H0V102.158H1.54294C1.86217 102.212 2.1814 102.318 2.39422 102.531C2.60704 102.744 2.71345 103.01 2.71345 103.329ZM1.64935 102.69C1.54294 102.637 1.43653 102.637 1.33012 102.637H0.585255V104.233H1.54294C1.70256 104.18 1.86217 104.074 1.96858 103.914C2.07499 103.755 2.1282 103.595 2.1282 103.382C2.1282 103.222 2.07499 103.063 2.02179 102.956C1.91538 102.85 1.80897 102.797 1.64935 102.69Z"
        fill="#002A41"
      />
      <path
        d="M9.47041 106.84H8.72554L6.96978 104.446V106.84H6.38452V102.105H8.14029C8.45952 102.105 8.77874 102.212 8.99156 102.478C9.20438 102.744 9.31079 103.01 9.31079 103.382C9.31079 103.542 9.25759 103.701 9.20438 103.861C9.15118 104.021 9.04477 104.18 8.88516 104.287C8.72554 104.393 8.56592 104.499 8.35311 104.553H7.71465L9.47041 106.84ZM8.19349 102.69C8.14029 102.69 8.08708 102.637 8.03388 102.637C7.98067 102.637 7.92747 102.637 7.87426 102.637H6.91657V104.233H7.98067C8.19349 104.233 8.3531 104.18 8.45951 104.021C8.56592 103.861 8.61913 103.701 8.61913 103.488C8.61913 103.329 8.56593 103.169 8.51272 103.01C8.45952 102.85 8.35311 102.744 8.19349 102.69Z"
        fill="#002A41"
      />
      <path
        d="M16.6 104.446C16.6 105.138 16.334 105.723 15.8551 106.255C15.3763 106.734 14.791 107 14.0461 107C13.3545 107 12.716 106.787 12.2372 106.308C11.7051 105.83 11.4923 105.244 11.4923 104.553C11.4923 103.861 11.7583 103.276 12.2372 102.744C12.716 102.265 13.3013 101.999 14.0461 101.999C14.7378 101.999 15.3763 102.212 15.8551 102.69C16.334 103.169 16.6 103.755 16.6 104.446ZM16.0147 104.446C16.0147 103.914 15.8019 103.435 15.4295 103.063C15.057 102.69 14.5782 102.478 13.9929 102.478C13.4609 102.478 12.982 102.69 12.6096 103.063C12.2372 103.435 12.0244 103.914 12.0244 104.499C12.0244 105.031 12.2372 105.51 12.6096 105.883C12.982 106.255 13.4609 106.468 14.0461 106.468C14.5782 106.468 15.057 106.255 15.4295 105.883C15.8551 105.457 16.0147 104.978 16.0147 104.446Z"
        fill="#002A41"
      />
      <path
        d="M21.8672 104.553H19.8986V106.894H19.3666V102.158H21.9204V102.637H19.9518V104.074H21.9204V104.553H21.8672Z"
        fill="#002A41"
      />
      <path
        d="M27.986 106.84H25.4321V102.105H27.986V102.637H25.9642V104.021H27.986V104.499H25.9642V106.308H27.986V106.84Z"
        fill="#002A41"
      />
      <path
        d="M34.5299 102.956L33.9979 103.169C33.9447 102.956 33.8383 102.797 33.6787 102.69C33.5191 102.584 33.3062 102.531 33.0934 102.531C32.9338 102.531 32.7742 102.584 32.6678 102.637C32.5614 102.69 32.455 102.797 32.3485 102.956C32.2953 103.063 32.2953 103.169 32.2953 103.329C32.2953 103.595 32.455 103.808 32.721 103.967L33.9447 104.393C34.1575 104.499 34.3171 104.659 34.4767 104.872C34.5832 105.085 34.6364 105.297 34.6364 105.564C34.6364 105.776 34.5832 105.989 34.5299 106.149C34.4235 106.362 34.3171 106.521 34.1575 106.628C33.8383 106.84 33.5191 106.947 33.1466 106.947C32.8274 106.947 32.5082 106.84 32.2421 106.681C32.0825 106.574 31.9761 106.468 31.9229 106.308C31.8697 106.149 31.7633 105.989 31.7101 105.776L32.2421 105.51C32.2421 105.776 32.3485 105.989 32.5082 106.202C32.721 106.362 32.9338 106.468 33.1998 106.468C33.3062 106.468 33.4126 106.468 33.5191 106.415C33.6255 106.362 33.7319 106.308 33.8383 106.255C33.9447 106.202 33.9979 106.096 34.0511 105.936C34.1043 105.83 34.1575 105.67 34.1575 105.564C34.1575 105.351 34.1043 105.191 33.9979 105.085C33.8915 104.978 33.8383 104.925 33.7851 104.872C33.7319 104.819 33.6255 104.765 33.4658 104.712L32.6678 104.446C32.4017 104.34 32.2421 104.18 32.0825 103.967C31.9229 103.755 31.8697 103.542 31.8697 103.276C31.8697 103.222 31.8697 103.116 31.9229 102.903C31.9761 102.744 32.0825 102.531 32.2421 102.371C32.4017 102.158 32.6146 102.052 32.8274 102.052C33.0402 101.999 33.1998 101.999 33.3594 101.999C33.4126 101.999 33.4658 101.999 33.5191 101.999C33.5723 101.999 33.6787 101.999 33.8383 102.052C33.9979 102.105 34.1575 102.158 34.3703 102.318C34.3171 102.531 34.4767 102.744 34.5299 102.956Z"
        fill="#002A41"
      />
      <path
        d="M39.7974 106.84H39.2654V102.105H39.7974V106.84Z"
        fill="#002A41"
      />
      <path
        d="M48.523 104.446C48.523 105.138 48.2569 105.723 47.7781 106.255C47.2992 106.734 46.714 107 45.9691 107C45.2775 107 44.639 106.787 44.1602 106.308C43.6281 105.83 43.4153 105.244 43.4153 104.553C43.4153 103.861 43.6813 103.276 44.1602 102.744C44.639 102.265 45.2243 101.999 45.9691 101.999C46.6608 101.999 47.2992 102.212 47.7781 102.69C48.2569 103.169 48.523 103.755 48.523 104.446ZM47.9377 104.446C47.9377 103.914 47.7249 103.435 47.3525 103.063C46.98 102.69 46.5012 102.478 45.9159 102.478C45.3839 102.478 44.905 102.69 44.5326 103.063C44.1602 103.435 43.9473 103.914 43.9473 104.499C43.9473 105.031 44.1602 105.51 44.5326 105.883C44.905 106.255 45.3839 106.468 45.9691 106.468C46.5012 106.468 46.98 106.255 47.3525 105.883C47.7781 105.457 47.9377 104.978 47.9377 104.446Z"
        fill="#002A41"
      />
      <path
        d="M54.4288 106.947H54.3224L50.8641 103.169V106.894H50.332V102.052H50.4916L53.8968 105.883V102.212H54.4288V106.947Z"
        fill="#002A41"
      />
      <path
        d="M60.8133 106.84H60.1748L59.5896 105.351H57.7274L57.1421 106.84H56.5037L58.5787 101.946H58.7383L60.8133 106.84ZM59.3767 104.978L58.5787 102.956L57.8338 104.978H59.3767Z"
        fill="#002A41"
      />
      <path
        d="M66.1339 106.84H63.7928V102.105H64.3781V106.308H66.1339V106.84Z"
        fill="#002A41"
      />
      <path
        d="M72.6781 106.84H70.1243V102.105H72.6781V102.637H70.6563V104.021H72.6781V104.499H70.6563V106.308H72.6781V106.84Z"
        fill="#002A41"
      />
      <path
        d="M79.2223 102.956L78.6903 103.169C78.6371 102.956 78.5307 102.797 78.371 102.69C78.2114 102.584 77.9986 102.531 77.7858 102.531C77.6262 102.531 77.4666 102.584 77.3601 102.637C77.2537 102.69 77.1473 102.797 77.0409 102.956C76.9877 103.063 76.9877 103.169 76.9877 103.329C76.9877 103.595 77.1473 103.808 77.4134 103.967L78.6371 104.393C78.8499 104.499 79.0095 104.659 79.1691 104.872C79.2755 105.085 79.3287 105.297 79.3287 105.564C79.3287 105.776 79.2755 105.989 79.2223 106.149C79.1159 106.362 79.0095 106.521 78.8499 106.628C78.5307 106.84 78.2114 106.947 77.839 106.947C77.5198 106.947 77.2005 106.84 76.9345 106.681C76.7749 106.574 76.6685 106.468 76.6153 106.308C76.5621 106.149 76.4557 105.989 76.4025 105.776L76.9345 105.51C76.9345 105.776 77.0409 105.989 77.2005 106.202C77.4134 106.362 77.6262 106.468 77.8922 106.468C77.9986 106.468 78.105 106.468 78.2114 106.415C78.3178 106.362 78.4243 106.308 78.5307 106.255C78.6371 106.202 78.6903 106.096 78.7435 105.936C78.7967 105.83 78.8499 105.67 78.8499 105.564C78.8499 105.351 78.7967 105.191 78.6903 105.085C78.5839 104.978 78.5307 104.925 78.4775 104.872C78.4243 104.819 78.3178 104.765 78.1582 104.712L77.3601 104.446C77.0941 104.34 76.9345 104.18 76.7749 103.967C76.6153 103.755 76.5621 103.542 76.5621 103.276C76.5621 103.222 76.5621 103.116 76.6153 102.903C76.6685 102.744 76.7749 102.531 76.9345 102.371C77.0941 102.158 77.3069 102.052 77.5198 102.052C77.7326 102.052 77.8922 101.999 78.0518 101.999C78.105 101.999 78.1582 101.999 78.2114 101.999C78.2646 101.999 78.371 101.999 78.5307 102.052C78.6903 102.105 78.8499 102.158 79.0627 102.318C79.0095 102.531 79.1159 102.744 79.2223 102.956Z"
        fill="#002A41"
      />
      <path
        d="M91.8319 106.84H89.2781V102.105H91.8319V102.637H89.8101V104.021H91.8319V104.499H89.8101V106.308H91.8319V106.84Z"
        fill="#002A41"
      />
      <path
        d="M99.121 106.947H99.0145L95.5562 103.169V106.894H95.0242V102.052H95.1838L98.5889 105.883V102.212H99.121V106.947Z"
        fill="#002A41"
      />
      <path
        d="M111.145 102.956L110.613 103.169C110.56 102.956 110.454 102.797 110.294 102.69C110.134 102.584 109.922 102.531 109.709 102.531C109.549 102.531 109.39 102.584 109.283 102.637C109.177 102.69 109.07 102.797 108.964 102.956C108.911 103.063 108.911 103.169 108.911 103.329C108.911 103.595 109.07 103.808 109.336 103.967L110.56 104.393C110.773 104.499 110.932 104.659 111.092 104.872C111.199 105.085 111.252 105.297 111.252 105.564C111.252 105.776 111.199 105.989 111.145 106.149C111.039 106.362 110.932 106.521 110.773 106.628C110.454 106.84 110.134 106.947 109.762 106.947C109.443 106.947 109.124 106.84 108.857 106.681C108.698 106.574 108.591 106.468 108.538 106.308C108.485 106.149 108.379 105.989 108.325 105.776L108.857 105.51C108.857 105.776 108.964 105.989 109.124 106.202C109.336 106.362 109.549 106.468 109.815 106.468C109.922 106.468 110.028 106.468 110.134 106.415C110.241 106.362 110.347 106.308 110.454 106.255C110.56 106.202 110.613 106.096 110.666 105.936C110.72 105.83 110.773 105.67 110.773 105.564C110.773 105.351 110.72 105.191 110.613 105.085C110.507 104.978 110.454 104.925 110.4 104.872C110.347 104.819 110.241 104.765 110.081 104.712L109.283 104.446C109.017 104.34 108.857 104.18 108.698 103.967C108.538 103.755 108.485 103.542 108.485 103.276C108.485 103.222 108.485 103.116 108.538 102.903C108.591 102.744 108.698 102.531 108.857 102.371C109.017 102.158 109.23 102.052 109.443 102.052C109.656 101.999 109.815 101.999 109.975 101.999C110.028 101.999 110.081 101.999 110.134 101.999C110.188 101.999 110.294 101.999 110.454 102.052C110.613 102.105 110.773 102.158 110.986 102.318C110.932 102.531 111.039 102.744 111.145 102.956Z"
        fill="#002A41"
      />
      <path
        d="M118.754 104.446C118.754 105.138 118.488 105.723 118.009 106.255C117.53 106.734 116.945 107 116.2 107C115.508 107 114.87 106.787 114.391 106.308C113.859 105.83 113.646 105.244 113.646 104.553C113.646 103.861 113.912 103.276 114.391 102.744C114.87 102.265 115.455 101.999 116.2 101.999C116.891 101.999 117.53 102.212 118.009 102.69C118.488 103.169 118.754 103.755 118.754 104.446ZM118.168 104.446C118.168 103.914 117.955 103.435 117.583 103.063C117.211 102.69 116.732 102.478 116.147 102.478C115.614 102.478 115.136 102.69 114.763 103.063C114.391 103.435 114.178 103.914 114.178 104.499C114.178 105.031 114.391 105.51 114.763 105.883C115.136 106.255 115.614 106.468 116.2 106.468C116.732 106.468 117.211 106.255 117.583 105.883C117.955 105.457 118.168 104.978 118.168 104.446Z"
        fill="#002A41"
      />
      <path
        d="M123.595 106.84H121.254V102.105H121.839V106.308H123.595V106.84Z"
        fill="#002A41"
      />
      <path
        d="M130.831 102.105V105.351C130.831 105.617 130.778 105.883 130.618 106.149C130.459 106.415 130.246 106.628 129.98 106.734C129.607 106.894 129.288 106.947 128.916 106.947C128.543 106.947 128.224 106.84 127.905 106.681C127.586 106.521 127.426 106.308 127.319 106.096C127.213 105.883 127.16 105.723 127.107 105.563C127.053 105.457 127.053 105.351 127.053 105.244V102.052H127.639V105.244C127.692 105.457 127.745 105.67 127.852 105.829C127.958 105.989 128.064 106.149 128.277 106.255C128.49 106.362 128.756 106.415 129.022 106.415C129.235 106.415 129.394 106.362 129.554 106.308C129.714 106.255 129.873 106.149 129.98 106.042C130.086 105.936 130.193 105.776 130.246 105.67C130.299 105.563 130.352 105.404 130.352 105.297V105.244V102.105H130.831Z"
        fill="#002A41"
      />
      <path
        d="M137.003 106.628C136.79 106.734 136.577 106.787 136.311 106.84C136.098 106.894 135.832 106.894 135.619 106.894C135.3 106.894 134.981 106.84 134.662 106.734C134.342 106.628 134.076 106.468 133.864 106.255C133.385 105.723 133.119 105.138 133.119 104.446C133.119 103.967 133.225 103.595 133.438 103.276C133.651 102.956 133.81 102.69 134.023 102.531C134.236 102.371 134.396 102.212 134.555 102.158C134.715 102.105 134.874 102.052 134.981 101.999C135.087 101.999 135.194 101.946 135.3 101.946C135.406 101.946 135.513 101.946 135.619 101.946C135.779 101.946 135.939 101.946 136.098 101.999C136.258 102.052 136.471 102.052 136.63 102.105L137.003 102.265V102.903C136.63 102.637 136.151 102.478 135.672 102.478C135.353 102.478 135.034 102.531 134.768 102.69C134.502 102.797 134.236 103.01 134.076 103.276C133.97 103.435 133.864 103.648 133.81 103.808C133.757 104.021 133.704 104.233 133.704 104.446C133.704 104.819 133.81 105.138 133.97 105.457C134.13 105.776 134.396 105.989 134.715 106.149C135.034 106.308 135.353 106.415 135.672 106.415C136.045 106.415 136.364 106.308 136.63 106.149L136.949 105.936V106.628H137.003Z"
        fill="#002A41"
      />
      <path
        d="M141.898 106.84H141.365V102.105H141.898V106.84Z"
        fill="#002A41"
      />
      <path
        d="M150.677 104.446C150.677 105.138 150.41 105.723 149.932 106.255C149.453 106.734 148.868 107 148.123 107C147.431 107 146.793 106.787 146.314 106.308C145.782 105.83 145.569 105.244 145.569 104.553C145.569 103.861 145.835 103.276 146.314 102.744C146.793 102.265 147.378 101.999 148.123 101.999C148.814 101.999 149.453 102.212 149.932 102.69C150.41 103.169 150.677 103.755 150.677 104.446ZM150.091 104.446C150.091 103.914 149.878 103.435 149.506 103.063C149.134 102.69 148.655 102.478 148.069 102.478C147.537 102.478 147.059 102.69 146.686 103.063C146.314 103.435 146.101 103.914 146.101 104.499C146.101 105.031 146.314 105.51 146.686 105.883C147.059 106.255 147.537 106.468 148.123 106.468C148.655 106.468 149.134 106.255 149.506 105.883C149.878 105.457 150.091 104.978 150.091 104.446Z"
        fill="#002A41"
      />
      <path
        d="M156.582 106.947H156.423L152.964 103.169V106.894H152.432V102.052H152.592L155.997 105.883V102.212H156.529V106.947H156.582Z"
        fill="#002A41"
      />
      <path
        d="M162.062 106.84H159.508V102.105H162.062V102.637H160.04V104.021H162.062V104.499H160.04V106.308H162.062V106.84Z"
        fill="#002A41"
      />
      <path
        d="M168.553 102.956L168.021 103.169C167.968 102.956 167.862 102.797 167.702 102.69C167.542 102.584 167.33 102.531 167.117 102.531C166.957 102.531 166.797 102.584 166.691 102.637C166.585 102.69 166.478 102.797 166.372 102.956C166.319 103.063 166.319 103.169 166.319 103.329C166.319 103.595 166.478 103.808 166.744 103.967L167.968 104.393C168.181 104.499 168.34 104.659 168.5 104.872C168.606 105.085 168.66 105.297 168.66 105.564C168.66 105.776 168.606 105.989 168.553 106.149C168.447 106.362 168.34 106.521 168.181 106.628C167.862 106.84 167.542 106.947 167.17 106.947C166.851 106.947 166.531 106.84 166.265 106.681C166.106 106.574 165.999 106.468 165.946 106.308C165.893 106.149 165.787 105.989 165.733 105.776L166.265 105.51C166.265 105.776 166.372 105.989 166.531 106.202C166.744 106.362 166.957 106.468 167.223 106.468C167.33 106.468 167.436 106.468 167.542 106.415C167.649 106.362 167.755 106.308 167.862 106.255C167.968 106.202 168.021 106.096 168.074 105.936C168.128 105.83 168.181 105.67 168.181 105.564C168.181 105.351 168.128 105.191 168.021 105.085C167.915 104.978 167.862 104.925 167.808 104.872C167.755 104.819 167.649 104.765 167.489 104.712L166.691 104.446C166.425 104.34 166.265 104.18 166.106 103.967C165.946 103.755 165.893 103.542 165.893 103.276C165.893 103.222 165.893 103.116 165.946 102.903C165.999 102.744 166.106 102.531 166.265 102.371C166.425 102.158 166.638 102.052 166.851 102.052C167.064 101.999 167.223 101.999 167.383 101.999C167.436 101.999 167.489 101.999 167.542 101.999C167.596 101.999 167.702 101.999 167.862 102.052C168.021 102.105 168.181 102.158 168.394 102.318C168.394 102.531 168.5 102.744 168.553 102.956Z"
        fill="#002A41"
      />
      <path
        d="M11.4923 80.2379L9.47048 81.1956C9.31087 80.3975 8.83202 79.8123 8.14036 79.3334C7.4487 78.8546 6.65062 78.5886 5.79934 78.5886C5.16088 78.5886 4.62883 78.695 4.14999 78.961C3.67114 79.227 3.29871 79.5995 2.97948 80.1847C2.76666 80.6636 2.66025 81.0892 2.66025 81.568C2.66025 82.6853 3.1923 83.5366 4.2564 84.1219L9.04484 85.8776C9.89612 86.3565 10.5878 87.0482 11.0666 87.8462C11.5455 88.6975 11.7583 89.5488 11.7583 90.5065C11.7583 91.3046 11.5987 92.1026 11.2795 92.8475C10.9602 93.5924 10.4814 94.2308 9.78971 94.8161C8.6192 95.6142 7.28908 96.0398 5.79934 96.0398C4.46922 96.0398 3.29871 95.7206 2.1814 95.0289C1.59615 94.6033 1.1173 94.0712 0.851279 93.5392C0.53205 93.0071 0.266025 92.2622 0 91.411L2.02179 90.4001C2.02179 91.4642 2.39422 92.3686 3.13909 93.0603C3.88396 93.752 4.78845 94.1244 5.79934 94.1244C6.22498 94.1244 6.59742 94.0712 7.02306 93.9116C7.39549 93.8052 7.82113 93.5924 8.19356 93.2731C8.566 93.0071 8.88523 92.5815 9.09805 92.1026C9.31087 91.6238 9.41728 91.0917 9.41728 90.5597C9.41728 89.7616 9.25766 89.1764 8.88523 88.7507C8.51279 88.3783 8.24677 88.1123 8.03395 87.9526C7.82113 87.793 7.39549 87.6334 6.75703 87.3142L3.51153 86.1969C2.55384 85.718 1.75576 85.1328 1.17051 84.3347C0.585255 83.5366 0.266025 82.6853 0.266025 81.6745C0.266025 81.4084 0.31923 80.9828 0.478845 80.2911C0.585255 79.5995 1.01089 78.9078 1.70256 78.1629C2.39422 77.4181 3.13909 76.9924 3.93717 76.8328C4.73524 76.6732 5.42691 76.5668 6.06537 76.5668C6.33139 76.5668 6.54421 76.5668 6.70383 76.5668C6.86344 76.5668 7.23588 76.6732 7.87433 76.7796C8.51279 76.886 9.20446 77.2584 9.89612 77.8969C10.7474 78.5886 11.2262 79.3334 11.4923 80.2379Z"
        fill="#03BFD7"
      />
      <path
        d="M41.6596 86.1437C41.6596 88.9103 40.702 91.2513 38.7334 93.2199C36.7648 95.1885 34.4238 96.1462 31.6571 96.1462C28.8372 96.1462 26.443 95.1885 24.4212 93.3263C22.3994 91.4642 21.3885 89.1231 21.3885 86.4097C21.3885 83.643 22.3462 81.302 24.3148 79.3334C26.2834 77.3649 28.6244 76.4072 31.3911 76.4072C34.2109 76.4072 36.6052 77.3648 38.627 79.227C40.6487 81.1424 41.6596 83.4302 41.6596 86.1437ZM39.3718 86.1437C39.3718 84.0155 38.5738 82.2065 37.0308 80.7168C35.4879 79.227 33.6257 78.4822 31.4443 78.4822C29.3161 78.4822 27.5071 79.2802 26.0174 80.8232C24.5276 82.3661 23.7828 84.2283 23.7828 86.4097C23.7828 88.5379 24.5276 90.3469 26.0706 91.8366C27.6135 93.3263 29.4757 94.0712 31.6571 94.0712C33.7853 94.0712 35.5943 93.2731 37.084 91.7302C38.627 90.134 39.3718 88.2719 39.3718 86.1437Z"
        fill="#03BFD7"
      />
      <path
        d="M59.5898 65.1277C59.5898 66.1386 59.4302 67.0431 59.111 67.8412C58.7918 68.6393 58.2597 69.3309 57.5148 69.9162C57.036 70.2354 56.5571 70.4482 55.9719 70.6078C55.4398 70.7675 54.8546 70.8207 54.2693 70.8207C53.5777 70.8207 52.9392 70.7143 52.3007 70.5546C51.6623 70.3418 51.077 70.0758 50.545 69.7034C49.9065 69.1181 49.3745 68.4796 49.0552 67.6816C48.6828 66.9367 48.5232 66.0854 48.5232 65.2341C48.5232 65.0213 48.5232 64.8085 48.5232 64.7021C48.5232 64.5957 48.5764 64.17 48.736 63.4252C48.8424 62.6803 49.3745 61.9354 50.279 61.0842C51.1834 60.2329 52.1943 59.754 53.4181 59.5944C54.695 59.5944 55.7591 59.754 56.7168 60.0733C57.6212 60.3925 58.3661 61.031 58.8982 61.9354L57.8873 62.7867C57.4616 62.0951 56.8764 61.6162 56.2379 61.2438C55.5995 60.8713 54.9078 60.7117 54.1097 60.7117C53.7373 60.7117 53.418 60.7649 53.0456 60.8181C52.0879 61.1374 51.2899 61.7226 50.7578 62.5207C50.1725 63.372 49.9065 64.2765 49.9065 65.2874C49.9065 66.2982 50.1726 67.1495 50.7046 67.8412C51.2366 68.5329 51.7687 69.0117 52.4072 69.2777C53.0456 69.5437 53.6841 69.7034 54.3757 69.7034C54.9078 69.7034 55.4398 69.597 55.9187 69.4373C56.3975 69.2777 56.8232 69.0117 57.1424 68.6393C57.5148 68.32 57.7809 67.9476 57.9405 67.522C58.1533 67.0963 58.2597 66.6707 58.3129 66.245L55.1206 66.1918V65.1277H59.5898Z"
        fill="#002A41"
      />
      <path
        d="M72.7844 70.6078H71.0286L66.985 65.0745V70.6078H65.6017V59.8072L69.5921 59.8604C70.3369 59.8604 70.9754 60.1264 71.5074 60.7117C71.9863 61.2969 72.2523 61.9886 72.2523 62.7335C72.2523 63.1591 72.1991 63.5315 72.0395 63.8508C71.8799 64.2232 71.6671 64.5424 71.3478 64.8085C70.9754 65.0745 70.6029 65.2873 70.1773 65.4469H68.7408L72.7844 70.6078ZM69.8049 61.0841C69.6985 61.0309 69.5388 60.9777 69.3792 60.9777C69.2196 60.9245 69.1132 60.9245 69.0068 60.9245H66.9318V64.5424H69.326C69.8049 64.5424 70.1773 64.3828 70.3901 64.0636C70.6562 63.7444 70.7626 63.3187 70.7626 62.8931C70.7626 62.5206 70.6562 62.1482 70.4965 61.829C70.3901 61.5097 70.1241 61.2437 69.8049 61.0841Z"
        fill="#002A41"
      />
      <path
        d="M87.5754 59.8072L87.5222 67.1495C87.5222 67.7879 87.3625 68.4264 86.9901 69.0116C86.6177 69.5969 86.1388 70.0225 85.6068 70.3418C84.8087 70.7142 83.9574 70.8738 83.1061 70.8738C82.2549 70.8738 81.4568 70.661 80.8183 70.2886C80.1267 69.9161 79.701 69.4373 79.435 68.9584C79.2222 68.4264 79.0626 68.054 79.0094 67.7879C78.9562 67.5219 78.903 67.3091 78.903 67.0963V59.8072H80.1799V67.0963C80.2331 67.6283 80.3927 68.054 80.6055 68.4796C80.8183 68.9052 81.1376 69.1713 81.5632 69.3841C82.0953 69.5969 82.6805 69.7565 83.2126 69.7565C83.6382 69.7565 84.0638 69.7033 84.4363 69.5437C84.8087 69.3841 85.1279 69.1713 85.394 68.9052C85.66 68.6392 85.8728 68.32 85.9792 68.054C86.1388 67.7879 86.192 67.4687 86.192 67.1495V67.0431V59.8604H87.5754V59.8072Z"
        fill="#002A41"
      />
      <path
        d="M101.143 62.4674C101.143 62.6271 101.143 62.7867 101.143 62.8399C101.143 63.5847 100.877 64.2232 100.398 64.8085C99.9189 65.3405 99.2804 65.6597 98.4823 65.7129H96.2477V70.6078H94.9176V59.8072L98.4291 59.8604C99.174 59.9668 99.8657 60.2328 100.345 60.7117C100.877 61.1373 101.143 61.7758 101.143 62.4674ZM98.7484 61.1373C98.5355 61.0841 98.2695 60.9777 98.0567 60.9777H96.3009V64.6488H98.4823C98.908 64.5424 99.2272 64.2764 99.4932 63.904C99.7593 63.5315 99.8657 63.1591 99.8657 62.7335C99.8657 62.361 99.7593 62.0418 99.5996 61.7758C99.3336 61.5097 99.0676 61.2969 98.7484 61.1373Z"
        fill="#002A41"
      />
      <path
        d="M118.328 65.1277C118.328 66.7239 117.743 68.1072 116.625 69.2245C115.508 70.3418 114.125 70.927 112.529 70.927C110.933 70.927 109.496 70.395 108.379 69.2777C107.208 68.2136 106.623 66.8303 106.623 65.2873C106.623 63.6912 107.208 62.3078 108.325 61.1905C109.443 60.0732 110.826 59.488 112.422 59.488C114.018 59.488 115.455 60.02 116.572 61.1373C117.743 62.2014 118.328 63.5316 118.328 65.1277ZM116.998 65.1277C116.998 63.904 116.572 62.8399 115.668 61.9886C114.763 61.1373 113.699 60.7117 112.422 60.7117C111.199 60.7117 110.134 61.1373 109.283 62.0418C108.432 62.9463 108.006 64.0104 108.006 65.2873C108.006 66.511 108.432 67.5751 109.336 68.4264C110.241 69.2777 111.305 69.7033 112.582 69.7033C113.806 69.7033 114.87 69.2777 115.721 68.3732C116.572 67.4155 116.998 66.3514 116.998 65.1277Z"
        fill="#002A41"
      />
      <path
        d="M150.198 64.3828H125.83V65.7129H150.198V64.3828Z"
        fill="#002A41"
      />
      <path
        d="M40.4892 64.3828H16.1213V65.7129H40.4892V64.3828Z"
        fill="#002A41"
      />
      <path
        d="M133.757 19.4246C133.757 19.4246 127.586 -0.0484271 104.495 6.07014C92.6831 9.20924 68.1024 37.195 37.35 6.54899C37.35 6.54899 42.2448 44.3777 83.1595 40.2277C104.069 38.1527 108.325 14.0509 133.757 19.4246Z"
        fill="#CE492B"
      />
      <path
        d="M133.97 19.4246C133.97 19.4246 127.586 -0.793261 104.548 5.53813C92.7896 8.78363 66.4531 38.738 37.35 6.60223C37.35 6.60223 44.3198 43.2605 83.6383 38.738C106.623 36.0778 108.485 14.2637 133.97 19.4246Z"
        fill="url(#paint0_linear_103_18)"
      />
      <path
        d="M110.454 10.7522C118.754 8.73043 125.032 9.74132 129.714 11.9759C129.554 11.7631 129.341 11.5503 129.182 11.2843C124.659 6.65543 116.732 2.29262 104.069 6.60223C92.7895 10.433 69.1133 37.6739 39.3717 10.8586C39.3717 10.8586 39.3717 10.9118 39.4249 10.9118C68.262 42.8348 97.7908 13.8381 110.454 10.7522Z"
        fill="url(#paint1_linear_103_18)"
      />
      <path
        d="M81.6166 29.0015C50.8641 32.5662 40.3828 14.5298 37.2969 6.54901C37.7757 8.83682 45.5436 43.154 83.532 38.738C106.57 36.0778 108.432 14.1573 133.864 19.3182C133.864 19.3182 133.864 19.265 133.811 19.1586C102.845 11.4971 104.335 26.3945 81.6166 29.0015Z"
        fill="url(#paint2_linear_103_18)"
      />
      <path
        d="M133.491 9.20927C133.491 9.20927 127.213 3.62274 117.636 2.18621C109.709 1.0157 102.633 2.34583 94.9178 7.08107C94.9178 7.02786 115.242 -10.1573 133.491 9.20927Z"
        fill="#10B7CF"
      />
      <path
        d="M51.3428 35.6521C51.3428 35.6521 60.494 42.7284 73.8485 43.6861C84.8619 44.4841 94.4388 41.8771 104.548 34.588C104.548 34.588 78.3709 60.3392 51.3428 35.6521Z"
        fill="url(#paint3_linear_103_18)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_103_18"
          x1="37.3288"
          y1="21.7"
          x2="133.95"
          y2="21.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1167" stop-color="#F16B21" />
          <stop offset="0.4764" stop-color="#874098" />
          <stop offset="0.7907" stop-color="#0097BF" />
          <stop offset="1" stop-color="#0097BF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_103_18"
          x1="39.9741"
          y1="20.1495"
          x2="129.66"
          y2="9.36436"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1167" stop-color="#F16B21" />
          <stop offset="0.5253" stop-color="#874098" />
          <stop offset="1" stop-color="#0097BF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_103_18"
          x1="49.3978"
          y1="25.5667"
          x2="103.41"
          y2="12.0136"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1167" stop-color="#F16B21" />
          <stop offset="0.6094" stop-color="#874098" />
          <stop offset="1" stop-color="#0097BF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_103_18"
          x1="51.3306"
          y1="40.4752"
          x2="104.558"
          y2="40.4752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EB5124" />
          <stop offset="0.9419" stop-color="#F79426" />
        </linearGradient>
      </defs>
    </svg>

    <h1 class="titulo-bienvenida">¡Bienvenido/a!</h1>
    <button
      type="button"
      @click="goLogin"
      class="button-general"
      id="btn-inicio"
    >
      <slot name="footer"> Empezamos </slot>
      <img
        src="../assets/right-arrow.png"
        alt="Right arrow"
        class="right-arrow"
      />
    </button>
    <img
      src="../assets/group-bottom.png"
      alt="Bottom Image"
      class="bottom-image"
    />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useIndexedDB } from "@/composables/useIndexedDB";

const router = useRouter();
const useIndexDB = useIndexedDB();
const goLogin = () => {
  router.push({ name: "login" });
};
useIndexDB.createdDb();
</script>
