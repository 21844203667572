import axios from "axios";

axios.defaults.withCredentials = true;

const token = localStorage.getItem("auth:token") || "";

axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

let axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_APP_API_KEY,
});

export default axiosInstance;
